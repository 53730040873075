import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ReactTooltip from "react-tooltip";
import ReactLoading from "react-loading";
import { Multicall } from "ethereum-multicall";
import { getprovider } from "../actions/WalletConnecte";
import Web3 from "web3";
import Bostic_ABI from "../ABI/Bostic_ABI.json";
import BUSD_ABI from "../ABI/BUSD_ABI.json";
import $ from "jquery";
import Due_ABI from "../ABI/Due_ABI.json";
import config from "../config/config";
import copy from "copy-to-clipboard";
import { toastAlert } from "../helper/toastAlert";
import { InlineShareButtons } from "sharethis-reactjs";
import isEmpty from "../helper/isEmpty.js";
import {
  getPackage,
  ReRegisterContinu,
  getInformation,
  LandRCount,
  DynamicLR,
  RandLCount,
  GetAuthToken,
} from "../actions/users";
import {
  getUser,
  WithdrawAmount,
  getBidWithdraws,
  totalPriceCalc,
  getExpireDay,
  seaarchUserTree,
  BidClaimReward,
  getPlans,
  getBidingPercentage,
  getWithdrawHis,
  getWithdraws,
  getPopupInfos,
  ReClaimData,
  GetAllWithdraws,
} from "../actions/users";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { getFormatMulticall1 } from "../actions/common";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Moment from "moment";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  let [loader, setloader] = useState(false);
  const [address, setAddress] = useState("");
  let [copytxt, setcopytxt] = useState(false);
  let [copytxt1, setcopytxt1] = useState(false);
  const [userdetails, setUserdetails] = useState();
  const [busdbal, setBusdbal] = useState();
  const [duebal, setDuebal] = useState();
  const [bosticBalace, setBosticBalance] = useState();
  const [totalBostic, setTotalBostic] = useState();
  const [totalDuetic, setTotalDuetic] = useState();
  const [userBosticBal, setuserBosticBal] = useState();
  const [MaxBostic, setMaxBostic] = useState();
  const [accAmountDue, setAccAmountDue] = useState();
  const [userDueBal, setuserDuecBal] = useState();
  const [expitreDay, setExpireDay] = useState();
  const [dayDiff, setDayDiff] = useState();
  const [planId, setPlanId] = useState();
  const [searchId, setSearchId] = useState(userdetails?.userID);
  const [treeArr, setTreeArr] = useState([]);
  const [dueTree, setDueTree] = useState([]);
  const [bosValue, setBosValue] = useState();
  const [dueValue, setDueValue] = useState();
  const [MinWithdrBos, setMinWithdrBos] = useState(0);
  const [MinWithdrDue, setMinWithdrDue] = useState(0);
  const [Maxlimit, setMaxLimit] = useState(0);
  const [left, SetLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [referalAmounts, setReferalAmounts] = useState(0);
  const [biddingRewards, setbiddingRewards] = useState(0);
  const [BidingPer, setBidingPer] = useState([]);
  const [latestBidPer, setLatestBidPer] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [LRCo, setLRCO] = useState("");
  const [FindName, setFindName] = useState([]);
  const [FindID, setFindID] = useState(0);
  const [DueTreFas, setDueTruFal] = useState(false);
  const [newCommer, setNewCommer] = useState(0);
  const [plan1, setPlan1] = useState(100);
  const [plan2, setPlan2] = useState();
  const [plan3, setPlan3] = useState();
  const [plan4, setPlan4] = useState();
  const [plan5, setPlan5] = useState();
  const [compAmount, setCompAmount] = useState();
  const [planId1, setPlanId1] = useState();
  const [planId2, setPlanId2] = useState();
  const [planId3, setPlanId3] = useState();
  const [planId4, setPlanId4] = useState();
  const [planId5, setPlanId5] = useState();
  const [status1, setStatus1] = useState(true);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  const [status4, setStatus4] = useState(false);
  const [status5, setStatus5] = useState(false);
  const [infor, setInfor] = useState("");
  const [Pendings, setPendings] = useState({
    Bostic: 0,
    Due: 0,
    biddingBos: 0,
  });
  const [BosPend, setBosPend] = useState(0);
  const [DuePend, setDuePend] = useState(0);
  const [BosBidPend, setBosBidPend] = useState(0);
  const [HighliBtn, setHighliBtn] = useState(false);
  const [Maxreach, setMaxReach] = useState(0);
  const [popup, setPopup] = useState(false);
  const [popupdata, setPopupData] = useState();
  const [popupImg, setPopupImg] = useState();
  const [titlePop, settitlePop] = useState("");
  const [rejectBos, setRejectBos] = useState("");
  const [rejectDue, setRejectDue] = useState("");
  const [rejectBid, setRejectBid] = useState("");
  const [Plann1, setPlannn1] = useState("");
  const [Plann2, setPlannn2] = useState("");
  const [Plann3, setPlannn3] = useState("");
  const [Plann4, setPlannn4] = useState("");
  const [Plann5, setPlannn5] = useState("");
  const [Plann6, setPlannn6] = useState("");
  const [Plann7, setPlannn7] = useState("");
  const [Pack1, setPack1] = useState("");
  const [Pack2, setPack2] = useState("");
  const [Pack3, setPack3] = useState("");
  const [Pack4, setPack4] = useState("");
  const [Pack5, setPack5] = useState("");
  const [Pack6, setPack6] = useState("");
  const [Pack7, setPack7] = useState("");
  const [withDraws, setWithDraws] = useState([]);
  const [WithDrawBos, setWithDrawBos] = useState(0);
  const [WithDrawDue, setWithDrawDue] = useState(0);
  const [WithDrawBos1, setWithDrawBos1] = useState(0);
  const [WithDrawDue1, setWithDrawDue1] = useState(0);
  const [LeftCount, setLeftfCount] = useState(0);
  const [RightCount, setRightCount] = useState(0);
  const [LeftVolume, setLeftVolume] = useState(0);
  const [RightVolume, setRightVolume] = useState(0);
  const [bidClaimRes, setBidClaimRes] = useState(0);
  const [DaysDiff, setDaysDiff] = useState(0);
  const [srchLR, setSrchLR] = useState(false);
  const [Dueue, setDueue] = useState(false);
  const [bidClimed, setBidClimed] = useState();

  const navigate = useNavigate();

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 20) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  useEffect(() => {
    getAddress();
    init();
    getExpireDa();
    getInfos();
    LeftRightCount();
    RightLeftCount();
  }, []);

  useEffect(() => {
    if (planId && planId) {
      getPlan();
      getWithdraw();
      getWithdrawsHH();
      setSearchId(userdetails?.userID);
      FindNames();
      // GetBiddingWiths();
    }
  }, [planId]);

  // const GetBiddingWiths = async () => {
  //   const payload = {
  //     uid: userdetails?.userID,
  //   };
  //   const data = await getBidWithdraws(payload);
  //   console.log(data, "fff");
  //   setBidClimed(data.user?.Data[0]?.totalBidAppr);
  // };

  const FindNames = async () => {
    const { provider, address } = await getprovider();

    if (
      !localStorage.getItem("account") ||
      localStorage.getItem("account") == undefined ||
      typeof localStorage.getItem("account") == "undefined"
    ) {
      navigate("/");
      return;
    }

    const payload = {
      address: address,
    };
    const data = await getUser(payload);
    data?.user?.allData.map((ele1) => {
      if (ele1.userID == userdetails?.userID) {
        setFindName(ele1.userName);
        setFindID(ele1.userID);
        dueTree.map((ele) => {
          if (ele.items.key === userdetails.userID) {
            setDueTruFal(true);
          }
        });
      }
    });
  };

  const LeftRightCount = async () => {
    const data = await LandRCount();
    setLeftfCount(data.user.lCount.length);
    setLeftVolume(data.user.sumOfLeft);
  };

  const RightLeftCount = async () => {
    const data = await RandLCount();
    setRightCount(data.user.rCount.length);
    setRightVolume(data.user.sumOfRight);
  };

  const getWithdrawsHH = async () => {
    let payload = {
      id: userdetails?.userID,
    };
    const data = await getWithdraws(payload);
    setWithDrawBos(
      data?.user?.Data1[0]?.totalRewards
        ? data?.user?.Data1[0]?.totalRewards
        : 0
    );
    setWithDrawDue(
      data?.user?.Data2[0]?.totalRewards
        ? data?.user?.Data2[0]?.totalRewards
        : 0
    );
    data.user.Data.map((ele, i) => {
      ele.SSno = i + 1;
    });
    setWithDraws(data.user.Data);

    const data1 = await GetAllWithdraws(payload);
    setWithDrawBos1(
      data1?.user?.Data1[0]?.totalRewards
        ? data1?.user?.Data1[0]?.totalRewards
        : 0
    );
    setWithDrawDue1(
      data1?.user?.Data2[0]?.totalRewards
        ? data1?.user?.Data2[0]?.totalRewards
        : 0
    );
  };

  const getInfos = async () => {
    const data = await getInformation();
    setInfor(data.user.Data?.information);
  };

  const getPlan = async () => {
    let payload = {
      plan: planId,
    };
    const data = await getPlans(payload);
    setMinWithdrBos(data.user.Data.depositLock);
    setMaxLimit(data.user.Data.cellingAmount);
    setMaxReach(data.user.Data.cellingAmount - 100);
  };

  const getAddress = async () => {
    const { provider, address } = await getprovider();
    let web3 = new Web3(provider);
    setAddress(address);
    const BusdContract = new web3.eth.Contract(BUSD_ABI, config.busdContract);
    let busdbal = await BusdContract.methods.balanceOf(address).call();
    busdbal = web3.utils.fromWei(busdbal);
    busdbal = Number(busdbal);
    setBusdbal(busdbal);
    const dueContract = new web3.eth.Contract(Due_ABI, config.DueContract);
    let duebal = await dueContract.methods.balanceOf(address).call();
    duebal = web3.utils.fromWei(duebal);
    duebal = Number(duebal);
    setDuebal(duebal);
    const bosticContract = new web3.eth.Contract(
      Bostic_ABI,
      config.bosticContract
    );
    let bosticbal = await bosticContract.methods.balanceOf(address).call();
    bosticbal = web3.utils.fromWei(bosticbal);
    bosticbal = Number(bosticbal);
    setBosticBalance(bosticbal);
  };

  const getExpireDa = async () => {
    const data = await getExpireDay();
    setExpireDay(data.user.Data?.gracePeriod);
    setBosValue(data.user.Data?.bosticValue);
    setDueValue(data.user.Data?.dueValue);
    setBidClaimRes(data.user.Data?.bidClaim);

    // setMinWithdrBos(data.user.Data.minimumWithdrawBos);
    setMinWithdrDue(data.user.Data?.minimumWithdrawDue);
    // setMaxLimit(data.user.Data.maxReachLimit);
  };

  const init = async () => {
    const { provider, address } = await getprovider();

    if (
      !localStorage.getItem("account") ||
      localStorage.getItem("account") == undefined ||
      typeof localStorage.getItem("account") == "undefined"
    ) {
      navigate("/");
      return;
    }

    const payload = {
      address: address,
    };
    const data = await getUser(payload);
    // data.user.Data?.RewardHistory.map((ele,i)=>{
    //     ele.SSno = i+1
    // })
    let SortData = data.user.Data?.RewardHistory;
    SortData?.reverse();
    SortData?.map((ele, i) => {
      ele.SSno = i + 1;
    });
    setBidingPer(SortData);
    setUserCount(data.user.allData.length);
    // setLRCO(data?.user?.Data);

    // setFindName(data?.user?.allData);
    setNewCommer(data.user.last24Hrs.length);
    setUserdetails(data.user.Data);
    setPlanId(data.user.Data?.plan);
    setReferalAmounts(data.user.refAllAmonuts);
    if (data.status === false) {
      navigate("/");
      return;
    }

    setRejectBos(data.user.Data.rejecetResionBos);
    setRejectDue(data.user.Data.rejecetResionDue);
    setRejectBid(data.user.Data.rejecetResionBid);
    var time2 = new Date(data.user.Data.createdAt).getTime();
    var time3 = new Date(data.user.Data.bidTime).getTime();
    var time1 = new Date().getTime(); //24 * 3600 * 1000 60000
    var daysdiff = Math.floor((time1 - time3) / 86400000);
    console.log(daysdiff, time3, time1, "daysdiff");
    setDaysDiff(daysdiff);
    var diff = Math.floor((time1 - time2) / 3.6e6);
    setDayDiff(diff);
    setuserBosticBal(
      data.user.Data.referealAmtBos - data.user.Data.withdrawAmtBos
    );
    setMaxBostic(data.user.Data.referealAmtBos);
    setuserDuecBal(data.user.Data.referealAmtDue);
    setAccAmountDue(data.user.Data.accumulationAmountDue);
    const payload1 = {
      uid: data.user.Data.userID,
    };
    console.log(payload1,'pay1');
    const data1 = await getBidWithdraws(payload1);
    console.log(data1, "fff");
    setBidClimed(data1.user?.Data[0]?.totalBidAppr);
    setbiddingRewards(
      data.user.Data?.biddingRewards - data1.user?.Data[0]?.totalBidAppr
        ? data1.user?.Data[0]?.totalBidAppr
        : 0
    );
    setCompAmount(data.user.Data?.companyAmount);
    const totalAmounts = await totalPriceCalc();
    setTotalBostic(totalAmounts.user.Data[0].totalBosAmount);
    setTotalDuetic(totalAmounts.user.Data[0].totalDueAmount);
  };

  const getWithdraw = async () => {
    const payload = {
      id: userdetails?.userID,
    };

    const data = await getWithdrawHis(payload);
    data.user.Data.map((ele) => {
      // if(ele.status === 0){
      if (ele._id === 0) {
        // setPendings({...Pendings,Bostic:ele.totalRewards})
        setBosPend(ele.totalRewards);
      }
      if (ele._id === 1) {
        // setPendings({...Pendings,Due:ele.totalRewards})
        setDuePend(ele.totalRewards);
      }
      if (ele._id === 2) {
        // setPendings({...Pendings,biddingBos:ele.totalRewards})
        setBosBidPend(ele.totalRewards);
      }

      // }
    });
  };

  const Withdraw = async () => {
    try {
      let { provider, address } = await getprovider();
      if (!provider && !address) {
        toastAlert("error", "Please Add Metamask Extension", "wallet");
        return;
      }

      let localaddress = localStorage.getItem("account");
      if (!isEmpty(localaddress)) {
        setloader(true);
        let { provider, address } = await getprovider();
        let web3 = new Web3(provider);
        let network = await web3.eth.net.getId();
        if (config.livechainid != network) {
          toastAlert("error", "Please connect binance mainnet", "wallet");
          setloader(false);
          return;
        }
        const payload = {
          user: userdetails.userID,
          continueOption: 1,
        };
        const data = WithdrawAmount(payload);
        if (data) {
          toastAlert("success", "Withdraw request sent successfully", "wallet");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const WithdrawExit = async () => {
    try {
      let { provider, address } = await getprovider();
      if (!provider && !address) {
        toastAlert("error", "Please Add Metamask Extension", "wallet");
        return;
      }

      let localaddress = localStorage.getItem("account");
      if (!isEmpty(localaddress)) {
        setloader(true);
        let { provider, address } = await getprovider();
        let web3 = new Web3(provider);
        let network = await web3.eth.net.getId();
        if (config.livechainid != network) {
          toastAlert("error", "Please connect binance mainnet", "wallet");
          setloader(false);
          return;
        }
        const payload = {
          user: userdetails.userID,
          continueOption: 0,
          ExitReq: true,
        };
        const data = WithdrawAmount(payload);
        if (data) {
          toastAlert("success", "Withdraw request sent successfully", "wallet");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(
    srchLR,
    "srchLRsrchLRsrchLRsrchLRsrchLRsrchLRsrchLRsrchLRsrchLRsrchLR"
  );

  const Funn = () => {
    for (let i = 0; i < dueTree.length; i++) {
      console.log(dueTree[i], "444444444444");
      if (dueTree[i].items.key == searchId) {
        setSrchLR(true);
        break;
      }
    }
  };
  useEffect(() => {
    Funn();
  }, [searchId]);

  const searchchange = (e) => {
    var ValNum = parseInt(e.target.value);
    setSearchId(ValNum);
    // alert(VaNum);
    for (let i = 0; i < dueTree.length; i++) {
      if (dueTree[i].items.key == ValNum) {
        setSrchLR(true);
        break;
      } else {
        setSrchLR(false);
      }
    }

    // dueTree.map((ele) => {
    //   console.log(ele, "elelleee");
    //   if (ele.items.key == VaNum) {

    //     console.log(ele.items.key, VaNum, "555");
    //     setSrchLR(true);
    //   } else {
    //     alert("false");

    //     setSrchLR(false);
    //     // return false;
    //   }
    // });
  };

  useEffect(() => {
    searchClick();
    getPlanPackage();
  }, []);

  useEffect(() => {
    getBidings();
    getPopup();
    getPkgse();
  }, []);

  const getPopup = async () => {
    const data = await getPopupInfos();
    setPopupData(data.user.Data.information);
    setPopupImg(data.user.Data.image);
    settitlePop(data.user.Data.title);
  };

  useEffect(() => {
    if (searchId) {
      DynamicLeftRight();
    }
  }, [searchId]);

  const DynamicLeftRight = async () => {
    const payload = {
      userId: searchId,
    };
    const data = await DynamicLR(payload);
    setLRCO(
      data?.user?.Data?.userID >= userdetails?.userID && data?.user?.Data
    );
  };

  const searchClick = async () => {
    let payload = {
      searchId: searchId,
      userId: userdetails?.userID,
    };
    const data = await seaarchUserTree(payload);
    data.user.Data.push({
      _id: "635baa912978337_ce386f3bc",
      type: 1,
      items: {
        key: 0,
        left: 0,
        right: 0,
        level: 0,
        parent: 0,
        rightCarryForward: 0,
        leftCarryForward: 0,
      },
    });
    setTreeArr(data.user.Data);
    data.user.Data1.push({
      _id: "635baa912978333_ce386f3b0o",
      type: 2,
      items: {
        key: 0,
        left: 0,
        right: 0,
        level: 0,
        parent: 0,
        rightCarryForward: 0,
        leftCarryForward: 0,
      },
    });
    setDueTree(data.user.Data1);
  };

  var userLevleft;
  var userLevright;

  const getBidings = async () => {
    const data = await getBidingPercentage();
    // setBidingPer(data.user.Data);
    setLatestBidPer(data.user.Data[0]?.percentage);
  };

  const ClaimReward = async () => {
    try {
      let { provider, address } = await getprovider();
      if (!provider && !address) {
        toastAlert("error", "Please Add Metamask Extension", "wallet");
        return;
      }

      let localaddress = localStorage.getItem("account");
      if (!isEmpty(localaddress)) {
        setloader(true);
        let { provider, address } = await getprovider();
        let web3 = new Web3(provider);
        let network = await web3.eth.net.getId();
        if (config.livechainid != network) {
          toastAlert("error", "Please connect binance mainnet", "wallet");
          setloader(false);
          return;
        }
        const payload = {
          user: userdetails.userID,
          claimOption: 1,
          claimAmount: biddingRewards,
        };
        const data = BidClaimReward(payload);
        if (data) {
          toastAlert(
            "success",
            "Bidding Reward Claim request sent successfully",
            "wallet"
          );
          window.location.reload();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPkgse = async () => {
    const data = await getPackage();
    setPlannn1(data.user.Data[0].planAmount);
    setPlannn2(data.user.Data[1].planAmount);
    setPlannn3(data.user.Data[2].planAmount);
    setPlannn4(data.user.Data[3].planAmount);
    setPlannn5(data.user.Data[4].planAmount);
    setPlannn6(data.user.Data[5].planAmount);
    setPlannn7(data.user.Data[6].planAmount);
    setPack1(data.user.Data[0].planName);
    setPack2(data.user.Data[1].planName);
    setPack3(data.user.Data[2].planName);
    setPack4(data.user.Data[3].planName);
    setPack5(data.user.Data[4].planName);
    setPack6(data.user.Data[5].planName);
    setPack7(data.user.Data[6].planName);
  };

  const getPlanPackage = async () => {
    try {
      const data = await getPackage();
      if (data.user.Data[2].planId === 3) {
        setPlan1(data.user.Data[2].planAmount);
        setPlanId1(data.user.Data[2].planId);
        setStatus1(data.user.Data[2].status);
      }
      if (data.user.Data[3].planId === 4) {
        setPlan2(data.user.Data[3].planAmount);
        setPlanId2(data.user.Data[3].planId);
        setStatus2(data.user.Data[3].status);
      }
      if (data.user.Data[4].planId === 5) {
        setPlan3(data.user.Data[4].planAmount);
        setPlanId3(data.user.Data[4].planId);
        setStatus3(data.user.Data[4].status);
      }
      if (data.user.Data[5].planId === 6) {
        setPlan4(data.user.Data[5].planAmount);
        setPlanId4(data.user.Data[5].planId);
        setStatus4(data.user.Data[5].status);
      }
      if (data.user.Data[6].planId === 7) {
        setPlan5(data.user.Data[6].planAmount);
        setPlanId5(data.user.Data[6].planId);
        setStatus5(data.user.Data[6].status);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ReRegister = async (level, pid) => {
    try {
      var userBosticBal1 = userBosticBal + accAmountDue;
      if (userBosticBal1 == 0 || userBosticBal1 < level) {
        var sendAmount = level - userBosticBal1;
        let { provider, address } = await getprovider();
        if (!provider && !address) {
          toastAlert("error", "Please Add Metamask Extension", "wallet");
          return;
        }
        setloader(true);
        let localaddress = localStorage.getItem("account");
        if (!isEmpty(localaddress)) {
          setloader(true);
          let { provider, address } = await getprovider();
          let web3 = new Web3(provider);
          let network = await web3.eth.net.getId();
          if (config.livechainid != network) {
            toastAlert("error", "Please connect binance mainnet", "wallet");
            setloader(false);
            return;
          }
          const BusdContract = new web3.eth.Contract(
            BUSD_ABI,
            config.busdContract
          );
          let busdbal = await BusdContract.methods.balanceOf(address).call();
          busdbal = web3.utils.fromWei(busdbal);
          if (busdbal >= sendAmount) {
            let bal = await web3.eth.getBalance(address);
            let val = web3.utils.fromWei(bal);
            var BNBLevel = sendAmount * config.decimalvalues;
            BNBLevel = convert(BNBLevel);
            let res = await BusdContract.methods
              .transfer(config.adminAddress, BNBLevel.toString())
              .send({ from: address })
              .then(async (res) => {
                const payload = {
                  user: userdetails?.userID,
                  continueOption: 1,
                  deductionAmount: userBosticBal1,
                };
                const data = await WithdrawAmount(payload);

                const payloadReReg = {
                  id: userdetails?.userID,
                  planId: pid,
                };
                const dataReReg = await ReRegisterContinu(payloadReReg);

                toastAlert(
                  "success",
                  "You continue the plan & Withdraw request sent successfully",
                  "wallet"
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((err) => {
                toastAlert("error", "Transaction rejected by user", "register");
                setloader(false);
              });
          } else {
            toastAlert("error", "You don't have a balance", "register");
            setloader(false);
          }
        }
      } else {
        const payload = {
          user: userdetails?.userID,
          continueOption: 1,
          deductionAmount: level,
        };

        const data = await WithdrawAmount(payload);

        const payloadReReg = {
          id: userdetails?.userID,
          planId: pid,
        };
        const dataReReg = await ReRegisterContinu(payloadReReg);

        toastAlert(
          "success",
          "You continue the plan & Withdraw request sent successfully",
          "wallet"
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ReClaim = async (ide) => {
    // alert(ide)
    try {
      let payload = {
        id: ide,
      };
      const data = await ReClaimData(payload);
      if (data) {
        toastAlert("success", "Reclaimed Request Sent Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.SSno,
      width: "100px",
      // cell: (row, index) => index,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      cell: (row) => {
        return Moment(row.date).format("DD-MM-YYYY hh:mm A");
      },
    },
    {
      name: "Percentage",
      width: "200px",
      selector: (row) => row.planper,
    },
    {
      name: "Bidding Reward",
      selector: (row) => row.biddingRewards.toFixed(3),
      width: "200px",
    },
  ];

  const datatd = BidingPer;

  const columns1 = [
    {
      name: "S.No",
      selector: (row) => row.SSno,
      width: "80px",
      // cell: (row, index) => index + 1,
    },
    {
      name: "Amount",
      width: "100px",
      selector: (row) => row.amount,
      cell: (row) => {
        return (
          <>
            {row.coin === 0
              ? "$ " + row.amount.toFixed(3)
              : row.coin === 1
              ? row.amount.toFixed(3)
              : row.amount.toFixed(3)}
          </>
        );
      },
    },
    {
      name: "User ID",
      width: "100px",
      selector: (row) => row.userID,
    },
    {
      name: "Coin",
      selector: (row) => row.status,
      width: "150px",
      cell: (row) => {
        return (
          <>
            {row.coin === 0
              ? "Bostic"
              : row.coin === 1
              ? "Due"
              : "Bostic (Bidding Reward)"}
          </>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "140px",
      cell: (row) => {
        return (
          <>
            {row.status === 0 ? (
              "Requested"
            ) : row.status === 1 ? (
              "Approved"
            ) : row.status === 2 ? (
              "Declain"
            ) : row.status === 3 ? (
              <>
                <button className="bidwthbtn" onClick={() => ReClaim(row._id)}>
                  Reclaim
                </button>
                {/* <br />
                <p style={{ position: "relative", top: "7px", left: "10px" }}>
                  {row.rejReason && row.rejReason}
                </p> */}
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      name: "Reason",
      selector: (row) => row.rejReason,
      width: "200px",
      cell: (row) => {
        return (
          <>
            {row.rejReason
              ? row.rejReason
              : row.decReason
              ? row.decReason
              : "-"}
          </>
        );
      },
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      cell: (row) => {
        return Moment(row.createdAt).format("DD-MM-YYYY hh:mm A");
      },
    },
  ];

  const datatd1 = withDraws;

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  $(window).on("load", function () {
    setPopup(true);
  });

  if (
    localStorage.getItem("showpopup") &&
    localStorage.getItem("showpopup") == "yes"
  ) {
    window.$("#toggleModal").modal("show");
  }

  function closeModal() {
    localStorage.removeItem("showpopup");
  }
  function Show() {
    window.$("#toggleModal").modal("show");
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <div className="bg_img">
        <Navbar />
        <div className="inner_wrapper">
          <div className="container">
            {infor && (
              <marquee
                style={{
                  padding: "12px",
                  position: "relative",
                  top: "-24px",
                  borderRadius: "10px",
                  background: "#000000",
                }}
              >
                {infor}
              </marquee>
            )}
            <div className="inner_wrapperbg">
              <div className="row">
                <div className="col-md-12">
                  <div className="title_flex">
                    <h2 className="inner_title">Dashboard</h2>
                    <div className="wallet_div">
                      <label>Your Wallet</label>
                      <div className="inp_grp">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          defaultValue={halfAddrShow(address)}
                          disabled={true}
                        />
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="copy_icon inp_append"
                          alt="Copy"
                          data-iscapture={true}
                          data-tip="copy address"
                          onClick={() => {
                            copy(address);
                            setcopytxt(true);
                            setTimeout(function () {
                              setcopytxt(false);
                            }, 800);
                          }}
                        />
                      </div>
                      {copytxt && (
                        <p
                          style={{
                            color: "green",
                            position: "absolute",
                            right: "0",
                            top: "38px",
                            fontSize: "12px",
                          }}
                        >
                          Copied
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="title_box">
                    <img
                      src={require("../assets/images/cup.png")}
                      className="img-fluid mr-3"
                      alt="Icon"
                    />
                    <div className="box">
                      <p>
                        {/* Can continue the plan by paying {MinWithdrBos} coins or
                        can withdraw and leave the plan */}
                        Can either withdraw and leave the plan or can continue
                        the plan by purchasing the packages.
                      </p>

                      {userBosticBal > 0 && MaxBostic >= Maxlimit && (
                        <button className="primary_btn" onClick={WithdrawExit}>
                          Withdraw&Exit
                        </button>
                      )}

                      {userBosticBal > 0 && MaxBostic < Maxlimit && (
                        <button className="primary_btn" onClick={Withdraw}>
                          Withdraw
                        </button>
                      )}

                      {MaxBostic >= Maxlimit && dayDiff < expitreDay && (
                        <>
                          <button
                            className="primary_btn"
                            // onClick={Continue}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            Continue
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row dashboard_top_wrap">
                    <div className="col-xl-3 box_left">
                      <div className="primary_box dash_box">
                        <div className="dash_box_left">
                          <h6 style={{ fontSize: "20px" }}>
                            {userdetails?.userName}
                          </h6>
                          <h4>ID {userdetails?.userID}</h4>
                          <h6 style={{ fontSize: "18px" }}>
                            {planId == 1
                              ? Pack1
                              : planId == 2
                              ? Pack2
                              : planId == 3
                              ? Pack3
                              : planId == 4
                              ? Pack4
                              : planId == 5
                              ? Pack5
                              : planId == 6
                              ? Pack6
                              : Pack7}{" "}
                            (
                            {planId == 1
                              ? "$ " + Plann1
                              : planId == 2
                              ? "$ " + Plann2
                              : planId == 3
                              ? "$ " + Plann3
                              : planId == 4
                              ? "$ " + Plann4
                              : planId == 5
                              ? "$ " + Plann5
                              : planId == 6
                              ? "$ " + Plann6
                              : "$ " + Plann7}
                            )
                          </h6>
                          {/* <div className="d-flex align-items-center">
                            <img
                              src={require("../assets/images/id_icon.png")}
                              className="img-fluid mr-3"
                              alt="Icon"
                            />
                            <h3>{userdetails?.referral?.length}</h3>
                          </div> */}
                        </div>
                        <div className="bor_top">
                          <h6>Balance in Bostic</h6>
                          <p>
                            <span
                              className={userBosticBal > Maxreach && `Hightbtn`}
                            >
                              {" "}
                              {userBosticBal
                                ? "$ " + userBosticBal
                                : "$ 0"}{" "}
                            </span>
                            {/* <span
                              className={userBosticBal > Maxreach && `Hightbtn`}
                            >
                              {" "}
                              {userBosticBal ? userBosticBal : "0"}{" "}
                            </span> */}
                            {/* {BosPend > 0 && (
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#7a7a7a",
                                  position: "relative",
                                  top: "-2px",
                                }}
                              >
                                (Pending : {BosPend ? BosPend : 0})
                              </span>
                            )} */}
                          </p>
                          <h6>Balance in Due</h6>
                          <p>
                            {userDueBal ? userDueBal + " Coin(s)" : "0 Coin(s)"}{" "}
                            {/* {DuePend > 0 && (
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#7a7a7a",
                                  position: "relative",
                                  top: "-2px",
                                }}
                              >
                                (Pending : {DuePend ? DuePend : 0})
                              </span>
                            )} */}
                          </p>
                          {MaxBostic >= Maxlimit && dayDiff <= expitreDay && (
                            <>
                              <h6>Balance in Accumulation</h6>
                              <p>{accAmountDue}</p>
                            </>
                          )}
                          <h6>Bidding Reward</h6>
                          <p>
                            {biddingRewards ? biddingRewards.toFixed(3) : "0"}{" "}
                            {console.log(DaysDiff, bidClaimRes, "DaysDiff")}
                            {biddingRewards > 0 && (
                              <span>
                                <button
                                  className="bidwthbtn"
                                  disabled={
                                    DaysDiff < bidClaimRes ? true : false
                                  }
                                  onClick={ClaimReward}
                                >
                                  Claim
                                </button>
                              </span>
                            )}
                          </p>

                          <h6>1 Bostic Coin = $ {bosValue}</h6>
                          <h6>1 Due Coin = $ {dueValue} </h6>
                        </div>
                      </div>

                      {/* <div className="primary_box dash_box">
                        <h4>Reject Reasons</h4>
                        {rejectBos ? (
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#7a7a7a",
                              position: "relative",
                              top: "0px",
                            }}
                          >
                            Bostic : {rejectBos}
                          </span>
                        ) : rejectBid ? (
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#7a7a7a",
                              position: "relative",
                              top: "0px",
                            }}
                          >
                            Due : {rejectBid}
                          </span>
                        ) : rejectBid ? (
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#7a7a7a",
                              position: "relative",
                              top: "0px",
                            }}
                          >
                            Due : {rejectBid}
                          </span>
                        ) : (
                          <>No Rejections</>
                        )}
                      </div> */}

                      {/* <div className="primary_box dash_box">
                        <h4>Your Downlines</h4>
                        <div className="d-flex align-items-center">
                          <img
                            src={require("../assets/images/id_icon.png")}
                            className="img-fluid mr-3"
                            alt="Icon"
                          />
                          <h3>{userdetails?.referral?.length}</h3>
                        </div>
                      </div> */}

                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_bottom">
                          <h4>Affiliate Link</h4>
                          <div className="inp_grp">
                            <input
                              type="text"
                              className="form-control primary_inp"
                              defaultValue={`${config.fronturl}joinnow/${address}`}
                            />
                            <img
                              src={require("../assets/images/copy_icon.png")}
                              className="copy_icon inp_append"
                              alt="Icon"
                              data-iscapture={true}
                              data-tip="copy link"
                              onClick={() => {
                                copy(`${config.fronturl}joinnow/${address}`);
                                setcopytxt1(true);
                                setTimeout(function () {
                                  setcopytxt1(false);
                                }, 800);
                              }}
                            />
                          </div>
                          {copytxt1 && (
                            <p
                              style={{
                                color: "green",
                                // position: "absolute",
                                // right: "0",
                                // top: "38px",
                                fontSize: "12px",
                              }}
                            >
                              Copied
                            </p>
                          )}
                          <ul className="footer_social_links">
                            {/* <InlineShareButtons
                              config={{
                                alignment: "center",
                                //color: 'social',
                                enabled: true,
                                font_size: 16,
                                language: "en",
                                networks: [
                                  "whatsapp",
                                  "telegram",
                                  "facebook",
                                  "twitter",
                                  "linkedin",
                                  "tumblr",
                                ],
                                padding: 8,
                                radius: 100,
                                show_total: false,
                                size: 30,
                                url: `${
                                  config.fronturl
                                }joinnow/${localStorage.getItem("account")}`,
                                description:
                                  "Bostic Coin marketing is powered by the revolutionary smart contract technology",
                                title: "Bostic Coin",
                                message: "Bostic Coin",
                                subject: "BosticCoin",
                                image:
                                  "https://www.doublerich.biz/static/media/logo.1e532a26d4b4ccea3187.png",
                                username: "BosticCoin",
                              }}
                            /> */}
                            <WhatsappShareButton
                              url={`${
                                config.fronturl
                              }joinnow/${localStorage.getItem("account")}`}
                            >
                              <WhatsappIcon size={30} round={true} />
                            </WhatsappShareButton>
                            <FacebookShareButton
                              url={`${
                                config.fronturl
                              }joinnow/${localStorage.getItem("account")}`}
                            >
                              <FacebookIcon size={30} round={true} />
                            </FacebookShareButton>
                            <TelegramShareButton
                              url={`${
                                config.fronturl
                              }joinnow/${localStorage.getItem("account")}`}
                            >
                              <TelegramIcon size={30} round={true} />
                            </TelegramShareButton>
                            <TwitterShareButton
                              url={`${
                                config.fronturl
                              }joinnow/${localStorage.getItem("account")}`}
                            >
                              <TwitterIcon size={30} round={true} />
                            </TwitterShareButton>
                            <LinkedinShareButton
                              url={`${
                                config.fronturl
                              }joinnow/${localStorage.getItem("account")}`}
                            >
                              <LinkedinIcon size={30} round={true} />
                            </LinkedinShareButton>
                            {/* <li>
                              <a href="javascript:void(0)" target="_blank">
                                <i className="fab fa-whatsapp"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" target="_blank">
                                <i className="fab fa-telegram-plane"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" target="_blank">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" target="_blank">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" target="_blank">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>

                      <div className="primary_box dash_box">
                        <h4>Retop up Count</h4>
                        <div className="d-flex align-items-center">
                          <img
                            src={require("../assets/images/id_icon.png")}
                            className="img-fluid mr-3"
                            alt="Icon"
                          />
                          <h3>{userdetails?.retopupTimes}</h3>
                        </div>
                      </div>
                      <div className="primary_box dash_box">
                        <h4>Bidding Percentage</h4>
                        <div className="d-flex align-items-center">
                          {/* <img
                            src={require("../assets/images/id_icon.png")}
                            className="img-fluid mr-3"
                            alt="Icon"
                          /> */}
                          <h3>{latestBidPer} %</h3>
                        </div>
                      </div>

                      {/* <h6 style={{ color: "#222" }}>Left': {left}</h6>
                      <h6 style={{ color: "#222" }}>Right: {right}</h6> */}

                      {/* modal design */}
                      <div
                        className={`modal fade primary_modal`}
                        id="toggleModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="toggleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            {/* <div className="modal-header">
                              <h5 className="modal-title" id="toggleModalLabel">
                                {titlePop ? titlePop : ""}
                              </h5>
                       
                            </div> */}
                            <div className="modal-body">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => closeModal()}
                              ></button>
                              <div>
                                <div className="m-2 mb-3 text-center">
                                  {popupImg && (
                                    <img
                                      src={`${config.backurl}/images/${popupImg}`}
                                      className="img-fluid my-2"
                                    />
                                  )}

                                  <p>{popupdata ? popupdata : ""}</p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="modal-footer"
                              style={{
                                borderTop: "1px solid rgb(228 167 18 / 0%)",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => closeModal()}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-9">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <img
                                  src={require("../assets/images/count_icon_participant.png")}
                                  className=""
                                  alt="Icon"
                                />
                                <h2>{userdetails?.referral?.length}</h2>
                                <h4>Total Referrals</h4>
                              </div>
                              <div>
                                <img
                                  src={require("../assets/images/count_icon_05.png")}
                                  className=""
                                  alt="Icon"
                                />

                                <h2>
                                  {" "}
                                  {userBosticBal != null
                                    ? userBosticBal + WithDrawBos1
                                    : 0}
                                </h2>
                                <h4>Total Earnings - Bostic</h4>
                              </div>
                              <div>
                                <img
                                  src={require("../assets/images/count_icon_04.png")}
                                  className=""
                                  alt="Icon"
                                />
                                <h2>
                                  {userDueBal != null
                                    ? userDueBal + WithDrawDue1
                                    : 0}
                                </h2>
                                <h4>Total Earnings - Due</h4>
                              </div>
                              <div>
                                <img
                                  src={require("../assets/images/count_icon_05.png")}
                                  className=""
                                  alt="Icon"
                                />
                                <h2>{WithDrawBos ? WithDrawBos : 0}</h2>
                                <h4>Total Withdrawal - Bostic</h4>
                              </div>
                              <div>
                                <img
                                  src={require("../assets/images/count_icon_04.png")}
                                  className=""
                                  alt="Icon"
                                />
                                <h2>{WithDrawDue ? WithDrawDue : 0}</h2>
                                <h4>Total Withdrawal - Due</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mobileForAlign">
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                          style={{ textAlign: "center" }}
                        >
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link active primary_btn"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Bostic Tree
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link primary_btn"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Due Tree
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="tree_box_panel">
                            <div className="tree_box tree_left">
                              <h2>Left</h2>
                              <h4>
                                Downlines :{" "}
                                {LRCo?.OverAllLeftUser
                                  ? LRCo?.OverAllLeftUser
                                  : 0}
                              </h4>
                              <h4>
                                Volume : $
                                {LRCo?.OverAllLeftbalance
                                  ? LRCo?.OverAllLeftbalance
                                  : 0}
                              </h4>
                            </div>
                            <div className="tree_box tree_right">
                              <h2>Right</h2>
                              <h4>
                                Downlines :{" "}
                                {LRCo?.OverAllRightUser
                                  ? LRCo?.OverAllRightUser
                                  : 0}
                              </h4>
                              <h4>
                                Volume : $
                                {LRCo?.OverAllRightbalance
                                  ? LRCo?.OverAllRightbalance
                                  : 0}
                              </h4>
                            </div>
                          </div>
                          <div className="inp_grp search_div">
                            <label>Search By ID</label>
                            <input
                              type="text"
                              className="form-control primary_inp"
                              onChange={searchchange}
                              value={searchId ? searchId : ""}
                            />
                            <button onClick={searchClick}>
                              {" "}
                              <img
                                src={require("../assets/images/search_icon.png")}
                                className=""
                                alt="Icon"
                              />
                            </button>
                          </div>
                          <>
                            {treeArr.length > searchId &&
                            searchId != "" &&
                            searchId >= userdetails?.userID ? (
                              <>
                                <div className="plan_wrapper">
                                  <p className="text-center mb-0 mt-5">
                                    {userdetails?.userID ==
                                      parseInt(searchId) && FindName}{" "}
                                    {"[ "} ID {searchId} {" ]"}
                                  </p>
                                  <div className="plan_pool">
                                    <div className="plan_pool_head">
                                      <img
                                        src={require("../assets/images/user_1.png")}
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                    </div>
                                    {/* First Row */}

                                    {treeArr.map((ele, i) => {
                                      i = i + 1;
                                      if (ele.items.key === parseInt(searchId))
                                        return (
                                          <>
                                            <span style={{ display: "none" }}>
                                              {(userLevleft = ele.items?.left)}{" "}
                                              {
                                                (userLevright =
                                                  ele.items?.right)
                                              }
                                            </span>
                                            <div className="plan_pool_first_row">
                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip1"
                                              >
                                                <img
                                                  src={require("../assets/images/user_2.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.left ==
                                                ele?.docs_left?.[0]?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip1"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.left ==
                                                    ele?.docs_left?.[0]?.userID
                                                      ? ele?.docs_left?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"["}{" "}
                                                    {ele.items?.left > 0
                                                      ? ele.items?.left
                                                      : "0"}{" "}
                                                    {"]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}

                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip2"
                                              >
                                                <img
                                                  src={require("../assets/images/user_2.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.right ==
                                                ele?.docs_right?.[0]
                                                  ?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip2"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.right ==
                                                    ele?.docs_right?.[0]?.userID
                                                      ? ele?.docs_right?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"["}{" "}
                                                    {ele.items?.right > 0
                                                      ? ele.items?.right
                                                      : "0"}{" "}
                                                    {"]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}
                                            </div>
                                          </>
                                        );
                                    })}

                                    <div className="plan_pool_second_row">
                                      {treeArr.map((ele, i) => {
                                        if (ele.items.key === userLevleft)
                                          return (
                                            <div>
                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip3"
                                              >
                                                <img
                                                  src={require("../assets/images/user_3.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.left ==
                                                ele?.docs_left?.[0]?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip3"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.left ==
                                                    ele?.docs_left?.[0]?.userID
                                                      ? ele?.docs_left?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}
                                                    {ele.items?.left > 0
                                                      ? ele.items?.left
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}

                                              <div
                                                className={`plan_pool_head_child ${
                                                  ele.items?.right ==
                                                  ele?.docs_right?.[0]?.userID
                                                    ? " "
                                                    : "hide"
                                                }`}
                                                data-tip
                                                data-for="id_tooltip4"
                                              >
                                                <img
                                                  src={require("../assets/images/user_3.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.right ==
                                                ele?.docs_right?.[0]
                                                  ?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip4"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.right ==
                                                    ele?.docs_right?.[0]?.userID
                                                      ? ele?.docs_right?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}
                                                    {ele.items?.right > 0
                                                      ? ele.items?.right
                                                      : "0"}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}
                                            </div>
                                          );
                                      })}
                                      {treeArr.map((ele, i) => {
                                        if (ele.items.key === userLevright)
                                          return (
                                            <div>
                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip5"
                                              >
                                                <img
                                                  src={require("../assets/images/user_3.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.left ==
                                                ele?.docs_left?.[0]?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip5"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.left ==
                                                    ele?.docs_left?.[0]?.userID
                                                      ? ele?.docs_left?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}
                                                    {ele.items?.left > 0
                                                      ? ele.items?.left
                                                      : "0"}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}

                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip6"
                                              >
                                                <img
                                                  src={require("../assets/images/user_3.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.right ==
                                                ele?.docs_right?.[0]
                                                  ?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip6"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.right ==
                                                    ele?.docs_right?.[0]?.userID
                                                      ? ele?.docs_right[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}{" "}
                                                    {ele.items?.right > 0
                                                      ? ele.items?.right
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}
                                            </div>
                                          );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="plan_wrapper">
                                  <p className="text-center mb-0 mt-5">
                                    {searchId >= userdetails?.userID
                                      ? "User Not Found"
                                      : searchId <= userdetails?.userID
                                      ? "Access Denied"
                                      : "User Not Found"}
                                  </p>
                                  <div className="plan_pool">
                                    <div className="plan_pool_head">
                                      <img
                                        src={require("../assets/images/user_1.png")}
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                    </div>
                                    {/* First Row */}
                                    <div className="plan_pool_first_row">
                                      <div
                                        className="plan_pool_head_child"
                                        data-tip
                                        data-for="id_tooltip1"
                                      >
                                        <img
                                          src={require("../assets/images/user_2.png")}
                                          className="img-fluid"
                                          alt="Icon"
                                        />
                                      </div>
                                      {/* <ReactTooltip
                                        id="id_tooltip1"
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        className="custom_tooltip"
                                        place="top"
                                      >
                                        <p className="mb-0">ID 0</p>
                                      </ReactTooltip> */}
                                      <div
                                        className="plan_pool_head_child"
                                        data-tip
                                        data-for="id_tooltip2"
                                      >
                                        <img
                                          src={require("../assets/images/user_2.png")}
                                          className="img-fluid"
                                          alt="Icon"
                                        />
                                      </div>
                                      {/* <ReactTooltip
                                        id="id_tooltip2"
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        className="custom_tooltip"
                                        place="top"
                                      >
                                        <p className="mb-0">ID 0</p>
                                      </ReactTooltip> */}
                                    </div>

                                    {/* Second Row */}
                                    <div className="plan_pool_second_row">
                                      <div>
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip3"
                                        >
                                          <img
                                            src={require("../assets/images/user_3.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip3"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip4"
                                        >
                                          <img
                                            src={require("../assets/images/user_3.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip4"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                      </div>
                                      <div>
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip5"
                                        >
                                          <img
                                            src={require("../assets/images/user_3.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip5"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip6"
                                        >
                                          <img
                                            src={require("../assets/images/user_3.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip6"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div className="tree_box_panel">
                            <div className="tree_box tree_left">
                              <h2>Left</h2>
                              <h4>
                                Downlines :{" "}
                                {LRCo?.OverAllLeftUserDue
                                  ? LRCo?.OverAllLeftUserDue
                                  : 0}
                              </h4>
                            </div>
                            <div className="tree_box tree_right">
                              <h2>Right</h2>
                              <h4>
                                Downlines :{" "}
                                {LRCo?.OverAllRightUserDue
                                  ? LRCo?.OverAllRightUserDue
                                  : 0}
                              </h4>
                            </div>
                          </div>
                          {/* Due Tree */}
                          <div className="inp_grp search_div">
                            <label>Search By ID</label>
                            <input
                              type="text"
                              className="form-control primary_inp"
                              onChange={searchchange}
                              value={searchId ? searchId : ""}
                            />
                            <button onClick={searchClick}>
                              {" "}
                              <img
                                src={require("../assets/images/search_icon.png")}
                                className=""
                                alt="Icon"
                              />
                            </button>
                          </div>

                          {/* Tree Flow Due */}

                          <>
                            {/* {dueTree.map((ele) => {
                              if (ele.items.key == searchId) {
                                setSrchLR(true);
                              }
                            })} */}

                            {/* dueTree.length >= searchId && */}
                            {searchId != "" &&
                            searchId >= userdetails?.userID &&
                            srchLR ? (
                              <>
                                <div className="plan_wrapper">
                                  <p className="text-center mb-0 mt-5">
                                    {userdetails?.userID ==
                                      parseInt(searchId) && FindName}{" "}
                                    {"[ "} ID {searchId} {" ]"}
                                  </p>
                                  <div className="plan_pool">
                                    <div className="plan_pool_head">
                                      <img
                                        src={require("../assets/images/122x136_user.png")}
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                    </div>
                                    {/* First Row */}

                                    {dueTree.map((ele, i) => {
                                      i = i + 1;
                                      if (ele.items.key === parseInt(searchId))
                                        return (
                                          <>
                                            <span style={{ display: "none" }}>
                                              {(userLevleft = ele.items?.left)}{" "}
                                              {
                                                (userLevright =
                                                  ele.items?.right)
                                              }
                                            </span>
                                            <div className="plan_pool_first_row">
                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip1"
                                              >
                                                <img
                                                  src={require("../assets/images/68x77_user.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.left ==
                                                ele?.docs_left?.[0]?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip1"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.left ==
                                                    ele?.docs_left?.[0]?.userID
                                                      ? ele?.docs_left?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}{" "}
                                                    {ele.items?.left > 0
                                                      ? ele.items?.left
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}

                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip2"
                                              >
                                                <img
                                                  src={require("../assets/images/68x77_user.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.right ==
                                                ele?.docs_right?.[0]
                                                  ?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip2"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.right ==
                                                    ele?.docs_right?.[0]?.userID
                                                      ? ele?.docs_right?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}{" "}
                                                    {ele.items?.right > 0
                                                      ? ele.items?.right
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}
                                            </div>
                                          </>
                                        );
                                    })}

                                    <div className="plan_pool_second_row">
                                      {dueTree.map((ele, i) => {
                                        if (ele.items.key === userLevleft)
                                          return (
                                            <div>
                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip3"
                                              >
                                                <img
                                                  src={require("../assets/images/48x54_user.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.left ==
                                                ele?.docs_left?.[0]?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip3"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.left ==
                                                    ele?.docs_left?.[0]?.userID
                                                      ? ele?.docs_left?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}
                                                    {ele.items?.left > 0
                                                      ? ele.items?.left
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}

                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip4"
                                              >
                                                <img
                                                  src={require("../assets/images/48x54_user.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.right ==
                                                ele?.docs_right?.[0]
                                                  ?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip4"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.right ==
                                                    ele?.docs_right?.[0]?.userID
                                                      ? ele?.docs_right?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}{" "}
                                                    {ele.items?.right > 0
                                                      ? ele.items?.right
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}
                                            </div>
                                          );
                                      })}
                                      {dueTree.map((ele, i) => {
                                        if (ele.items.key === userLevright)
                                          return (
                                            <div>
                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip5"
                                              >
                                                <img
                                                  src={require("../assets/images/48x54_user.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.left ==
                                                ele?.docs_left?.[0]?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip5"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.left ==
                                                    ele?.docs_left?.[0]?.userID
                                                      ? ele?.docs_left?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}{" "}
                                                    {ele.items?.left > 0
                                                      ? ele.items?.left
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}

                                              <div
                                                className="plan_pool_head_child"
                                                data-tip
                                                data-for="id_tooltip6"
                                              >
                                                <img
                                                  src={require("../assets/images/48x54_user.png")}
                                                  className="img-fluid"
                                                  alt="Icon"
                                                />
                                              </div>
                                              {ele.items?.right ==
                                                ele?.docs_right?.[0]
                                                  ?.userID && (
                                                <ReactTooltip
                                                  id="id_tooltip6"
                                                  aria-haspopup="true"
                                                  role="example"
                                                  effect="solid"
                                                  className="custom_tooltip"
                                                  place="top"
                                                >
                                                  <p className="mb-0">
                                                    {/* ID{" "} */}
                                                    {ele.items?.right ==
                                                    ele?.docs_right?.[0]?.userID
                                                      ? ele?.docs_right?.[0]
                                                          ?.userName
                                                      : ""}{" "}
                                                    {"[ "}{" "}
                                                    {ele.items?.right > 0
                                                      ? ele.items?.right
                                                      : "0"}{" "}
                                                    {" ]"}
                                                  </p>
                                                </ReactTooltip>
                                              )}
                                            </div>
                                          );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="plan_wrapper">
                                  <p className="text-center mb-0 mt-5">
                                    {searchId >= userdetails?.userID
                                      ? "User Not Found"
                                      : searchId <= userdetails?.userID
                                      ? "Access Denied"
                                      : "User Not Found"}
                                  </p>
                                  <div className="plan_pool">
                                    <div className="plan_pool_head">
                                      <img
                                        src={require("../assets/images/122x136_user.png")}
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                    </div>
                                    {/* First Row */}
                                    <div className="plan_pool_first_row">
                                      <div
                                        className="plan_pool_head_child"
                                        data-tip
                                        data-for="id_tooltip1"
                                      >
                                        <img
                                          src={require("../assets/images/68x77_user.png")}
                                          className="img-fluid"
                                          alt="Icon"
                                        />
                                      </div>
                                      {/* <ReactTooltip
                                        id="id_tooltip1"
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        className="custom_tooltip"
                                        place="top"
                                      >
                                        <p className="mb-0">ID 0</p>
                                      </ReactTooltip> */}
                                      <div
                                        className="plan_pool_head_child"
                                        data-tip
                                        data-for="id_tooltip2"
                                      >
                                        <img
                                          src={require("../assets/images/68x77_user.png")}
                                          className="img-fluid"
                                          alt="Icon"
                                        />
                                      </div>
                                      {/* <ReactTooltip
                                        id="id_tooltip2"
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        className="custom_tooltip"
                                        place="top"
                                      >
                                        <p className="mb-0">ID 0</p>
                                      </ReactTooltip> */}
                                    </div>

                                    {/* Second Row */}
                                    <div className="plan_pool_second_row">
                                      <div>
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip3"
                                        >
                                          <img
                                            src={require("../assets/images/48x54_user.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip3"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip4"
                                        >
                                          <img
                                            src={require("../assets/images/48x54_user.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip4"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                      </div>
                                      <div>
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip5"
                                        >
                                          <img
                                            src={require("../assets/images/48x54_user.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip5"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                        <div
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for="id_tooltip6"
                                        >
                                          <img
                                            src={require("../assets/images/48x54_user.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                        </div>
                                        {/* <ReactTooltip
                                          id="id_tooltip6"
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          className="custom_tooltip"
                                          place="top"
                                        >
                                          <p className="mb-0">ID 0</p>
                                        </ReactTooltip> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>

                          {/* Tree FLow Due */}
                          {/* Due tree */}
                        </div>
                      </div>
                      {/*  */}

                      <div className="tabledeatils">
                        <h6>Bidding History</h6>
                        <DataTable
                          columns={columns}
                          data={datatd}
                          noHeader
                          pagination={true}
                          paginationPerPage="5"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                      <div className="tabledeatils">
                        <h6>Withdraw History</h6>
                        <DataTable
                          columns={columns1}
                          data={datatd1}
                          noHeader
                          pagination={true}
                          paginationPerPage="5"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Continu Model */}

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div style={{ background: "#303030" }} className="modal-content">
            <div
              className="modal-header"
              style={{ borderBottom: "1px solid #e4a712" }}
            >
              <h5
                className="modal-title"
                style={{ width: "100%", textAlign: "center" }}
                id="staticBackdropLabel"
              >
                Choose Plan
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div style={{ textAlign: "center" }} className="modal-body">
              {status1 && (
                <button
                  className="primary_btn mx-2"
                  onClick={() => ReRegister(plan1, planId1)}
                >
                  {plan1}
                </button>
              )}
              {status2 && (
                <button
                  className="primary_btn mx-2"
                  onClick={() => ReRegister(plan2, planId2)}
                >
                  {plan2}
                </button>
              )}
              {status3 && (
                <button
                  className="primary_btn mx-2"
                  onClick={() => ReRegister(plan3, planId3)}
                >
                  {plan3}
                </button>
              )}

              {status4 && (
                <button
                  className="primary_btn mx-2"
                  onClick={() => ReRegister(plan4, planId4)}
                >
                  {plan4}
                </button>
              )}

              {status5 && (
                <button
                  className="primary_btn mx-2"
                  onClick={() => ReRegister(plan5, planId5)}
                >
                  {plan5}
                </button>
              )}
            </div>
            <div
              className="modal-footer"
              style={{ borderTop: "1px solid #e4a712" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {loader && (
        <div className="loader_section_">
          <ReactLoading type={"cylon"} color={"#ffffff"} className="loading" />
        </div>
      )}
      <Footer />
    </div>
  );
}
