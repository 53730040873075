import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { getprovider } from "../actions/WalletConnecte";
import Bostic_ABI from "../ABI/Bostic_ABI.json";
import Due_ABI from '../ABI/Due_ABI.json'
import BUSD_ABI from "../ABI/BUSD_ABI.json";
import config from "../config/config";
import { getUser } from "../actions/users";

export default function Navbar(props) {
  var navigate = useNavigate();
  const [selected, setSelected] = useState("GB");
  let [address, setaddress] = useState();
  let [PopupStatus, setPopupStatus] = useState();
  let [balance, setbalance] = useState(0);
  let [tokenbalance, settokenbalance] = useState();
  let [dueBalance,setDuebalance] = useState(0)
  let [busdbal, setBusdbal] = useState();
  const [userIs, setUseris] = useState(false);
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }

  useEffect(() => {
    if (localStorage.getItem("account")) {
      init();
      // loadTrustwallet()
    }
  }, []);

  const init = async () => {
    let { provider, address } = await getprovider();
    const payload = {
      address: address,
    };
    const data = await getUser(payload);
    if (data && data.status == true) {
      setUseris(true);
    } else {
      setUseris(false);
    }
    let web3 = new Web3(provider);
    let bal = await web3.eth.getBalance(address);
    bal = web3.utils.fromWei(bal);
    setaddress(address);
    bal = Number(bal);
    setbalance(bal);
    let tokencontract = new web3.eth.Contract(
      Bostic_ABI,
      config.bosticContract
    );
    let dueTokContract = new web3.eth.Contract(
      Due_ABI,
      config.DueContract
    )
    let tbal = await tokencontract.methods.balanceOf(address).call();
    tbal = web3.utils.fromWei(tbal);
    tbal = Number(tbal);
    settokenbalance(tbal);

    let tbalDue = await dueTokContract.methods.balanceOf(address).call();
    tbalDue = web3.utils.fromWei(tbalDue);
    tbalDue = Number(tbalDue);
    setDuebalance(tbalDue);

    const BusdContract = new web3.eth.Contract(BUSD_ABI, config.busdContract);
    let busdbal = await BusdContract.methods.balanceOf(address).call();
    busdbal = web3.utils.fromWei(busdbal);
    busdbal = Number(busdbal);
    setBusdbal(busdbal);
  };

  async function disconnect() {
    // loadTrustwallet();
    localStorage.clear();
    navigate("/");
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 8) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const ConnectDash = () => {
    // localStorage.setItem("showpopup", "yes");
    navigate("/dashboard");
  }

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-light main_navbar">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            src={require("../assets/images/logo.png")}
            className="img-fluid"
            alt="logo"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="ml-auto navbar_right">
            <div className="dropdown">
              <span
                className="listItem"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {localStorage.getItem("account") ? (
                  <button className="logout_btn dropdown-toggle">
                    <p
                      className="address_btn"
                      style={{
                        marginBottom: "0",
                        // width: "156px",
                        // textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      {halfAddrShow(address)}
                      {/* {halfAddrShow(localStorage.getItem("account"))} */}
                    </p>
                  </button>
                ) : (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    Connect Wallet
                  </button>
                )}
              </span>
              {localStorage.getItem("account") && (
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <p>
                    <label>BNB Balance</label>
                    <span>
                      {balance ? <p>{balance.toFixed(8)} BNB</p> : <p>0 BNB</p>}
                    </span>
                  </p>
                  <p>
                    <label>Bostic Balance </label>
                    <span>
                      {tokenbalance ? (
                        <p>{tokenbalance.toFixed(8)} Bostic</p>
                      ) : (
                        <p>0 Bostic</p>
                      )}
                    </span>
                  </p>
                  <p>
                    <label>Due Balance </label>
                    <span>
                      {dueBalance ? (
                        <p>{dueBalance.toFixed(8)} Bostic</p>
                      ) : (
                        <p>0 Due</p>
                      )}
                    </span>
                  </p>
                  <p>
                    <label>BUSD Balance </label>
                    <span>
                      {busdbal ? (
                        <p>{busdbal.toFixed(8)} BUSD</p>
                      ) : (
                        <p>0 BUSD</p>
                      )}
                    </span>
                  </p>
                  {/* {user && (
                    <Link to={"/dashboard"} onClick={connect}>
                      Dashboard
                    </Link>
                  )} */}
                  <button
                    className="logout_btn w-100 d-block"
                    onClick={() => disconnect()}
                  >
                    <img
                      src={require("../assets/images/logout_btn.png")}
                      className="img-fluid"
                    />{" "}
                    Disconnect
                  </button>
                </div>
              )}
              {localStorage.getItem("account") && (
                <>
                  {userIs == true && (
                    <>
                      <button
                        onClick={ConnectDash}
                        className="primary_btn"
                        style={{ marginRight: "20px" }}
                      >
                        Dashboard
                      </button>
                      <button
                        onClick={() => (window.location.href = "/profile")}
                        className="primary_btn"
                      >
                        Profile
                      </button>
                    </>
                  )}
                </>
              )}
              {localStorage.getItem("account") && (
                <>
                  {userIs != true && (
                    <button
                      onClick={() => (window.location.href = "/joinnow")}
                      className="primary_btn"
                    >
                      Join Now
                    </button>
                  )}
                </>
              )}
            </div>

            {/* <button class="primary_btndlogout"> 
             <img  src={require("../assets/images/logout_btn.png")}  classname="copy_icon inp_append" alt="Copy" />
              </button>                */}
          </div>
        </div>
      </div>
    </nav>
  );
}
